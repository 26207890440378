import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { slide } from './lib/_slideshow.js';
//import { getContent } from './lib/_getContent.js';
import { rooms } from './lib/_rooms.js';
//import { activities } from './lib/_activities.js';

export class Framework {
	constructor(){
		this.pageinfo = loc.all();
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		this.changeContent();
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		let isPage = document.querySelector("main").getAttribute("data-page");
		if(isPage === "activities_plans"){
		//	activities.init();
		}
		rooms.init();
		common.init();
		//getContent.news();
		//getContent.activity();
		slide.init();
		common.resize();
		common.nav();
		let $body = document.querySelector("body");
		$body.classList.remove("disable");
	}
}