class Slideshow{
	constructor($dom){
		this.dom = $dom;
		this.lists = $dom.querySelectorAll("li.slide");
		this.current = 0;
		this.max = this.lists.length;
		
		this.canChange = true;
		this.startX = 0;
		this.moveX = 0;
		this.isDragging = false;
		this.target = $dom.querySelector(".c-slideshow");
		
		let navHTML = "";
		for(let i = 0; i < this.max; i++){
			navHTML += `<li><button data-index="${i}">${i}</button></li>`
		}
		$dom.querySelector(".c-nav_slideshow ul").innerHTML = navHTML;
		this.navList = $dom.querySelectorAll(".c-nav_slideshow ul li");
		this.setObserver();
		this.nav();
		this.checkNav();
	}
	setObserver(){
		let $this = this;
		let dom = this.dom.querySelector("ul");
		const options = {
			root: dom,
			rootMargin: "0px -50%",
			threshould: 0
		}
		//console.log("observer")
		const observer = new IntersectionObserver(doWhenIntersect, options);
		// それぞれのboxを監視する
		$this.lists.forEach(list => {
			observer.observe(list);
		});
		function doWhenIntersect(entries) {
			if (window.matchMedia('(max-width: 863px)').matches) {
				
			}
			$this.navList.forEach( ($list) => {
				if( $list.classList.contains("active") ){
					$list.classList.remove("active");
				}
			});
			entries.forEach( (entry, index) => {
				if (entry.isIntersecting) {
					$this.current = parseInt( entry.target.getAttribute("data-index") );
					//console.log($this.current);
					entry.target.classList.add("active");
					$this.navList[$this.current].classList.add("active");
					//ytSlideshow.checkNav();
				}else{
					if(entry.target.classList.contains("active")){
						entry.target.classList.remove("active");
					}
				}
			});
		}
	}
	nav(){
		let $this = this;
		$this.navList.forEach( ($nav) => {
			let $btn = $nav.querySelector("button");
			$btn.removeEventListener("click", { name: $this, handleEvent: $this.getIndex}, false);
			$btn.addEventListener("click", { name: $this, handleEvent: $this.getIndex}, false);
		});
		let $target = $this.dom.querySelector(".c-slideshow");
		$target.removeEventListener("mousedown", {name: $this, handleEvent: $this.touchStart}, false);
		$target.addEventListener("mousedown", {name: $this, handleEvent: $this.touchStart}, false);
		
		//mouse move event
		$target.removeEventListener("mousemove", {name: $this, handleEvent: $this.touchMove}, false);
		$target.addEventListener("mousemove", {name: $this, handleEvent: $this.touchMove}, false);
		
		$target.removeEventListener("mouseleave", {name: $this, handleEvent: $this.mouseleave}, false);
		$target.addEventListener("mouseleave", {name: $this, handleEvent: $this.mouseleave}, false);
		
		let $prev = $this.dom.querySelector("[data-prev]");
		let $next = $this.dom.querySelector("[data-next]");
		
		$prev.removeEventListener("click", {name: $this, handleEvent: $this.dec}, false);
		$prev.addEventListener("click", {name: $this, handleEvent: $this.dec}, false);
		
		$next.removeEventListener("click", {name: $this, handleEvent: $this.inc}, false);
		$next.addEventListener("click", {name: $this, handleEvent: $this.inc}, false);
	}
	getIndex(e){
		let $this = this;
		this.name.current = parseInt( e.currentTarget.getAttribute("data-index") );
		this.name.change();
	}
	inc(e){
		let $this;
		if(this.name){
			$this = this.name;
		}else{
			$this = this;
		}
		$this.current++;
		if( $this.current >= $this.max ){
			$this.current = $this.max - 1;
			$this.isDragging = false;
			$this.canChange = true;
		}else{
			$this.change();
		}
	}
	dec(e){
		let $this;
		if(this.name){
			$this = this.name;
		}else{
			$this = this;
		}
		$this.current--;
		if( $this.current < 0 ){
			$this.current = 0;
			$this.isDragging = false;
			$this.canChange = true;
		}else{
			$this.change();
		}
	}
	change(){
		let $this 	= this;
		$this.checkNav();
		let $target = $this.dom.querySelector(".c-slideshow");
		let w = $target.clientWidth;
		
		let posX = $this.current*w;
		//console.log($target, w, posX, $this.current);
		$target.scrollLeft = posX;
		setTimeout(function(){
			$this.canChange = true;
		});
	}
	touchStart(e){
		let $this = this.name;
		let $target = $this.target;
		if($this.canChange){
			e.preventDefault();
			if(!$target.classList.contains("touch")){
				$target.classList.add("touch");
				$this.isDragging = true;
			}
			$this.startX = e.clientX;
			$this.moveX = 0;
			$target.removeEventListener("mousemove", {name: $this, handleEvent: $this.touchMove}, false);
			$target.addEventListener("mousemove", {name: $this, handleEvent: $this.touchMove}, false);
			
			document.removeEventListener("mouseup", {name: $this, handleEvent: $this.touchEnd}, false);
			document.addEventListener("mouseup", {name: $this, handleEvent: $this.touchEnd}, false);
		}
	}
	touchMove(e){
		let $this = this.name;
		let $target = $this.target;
		if(!$this.canChange){ return false; }
		
		let currentX = 0;
		let diff = 0;
		if('ontouchstart' in window){
			
		}else{
			if($this.isDragging){
				currentX 	= e.clientX;
				diff 		= currentX - $this.startX;
				$this.moveX = diff;
				let posX 	= $this.current*$target.clientWidth - diff;
				//console.log(posX)
				//console.log(diff, posX);
				$target.scrollLeft = posX;
			}
		}
		document.removeEventListener("mouseup", {name: $this, handleEvent: $this.touchEnd}, false);
		document.addEventListener("mouseup", {name: $this, handleEvent: $this.touchEnd}, false);
	}
	mouseleave(e){
		let $this;
		if(this.name){
			$this = this.name;
		}else{
			$this = this;
		}
		if($this.target.classList.contains("touch")){
			$this.target.classList.remove("touch");
		}
		$this.isDragging = false;
		$this.canChange = true;
	}
	touchEnd(e){
		let $this = this.name;
		if($this.canChange){
			$this.canChange = false;
			let limit = 50;
			if($this.target.classList.contains("touch")){
				$this.target.classList.remove("touch");
				$this.isDragging = false;
			}
			if( $this.moveX < -limit ){
				$this.inc();
			}else if( $this.moveX > limit){
				$this.dec();
			}else{
				$this.mouseleave();
			}
		}else{
			
		}
	}
	checkNav(){
		let $this = this;
		let $prev = $this.dom.querySelector("[data-prev]");
		let $next = $this.dom.querySelector("[data-next]");
		if($this.current >= $this.max-1){
			if( !$next.classList.contains("disable") ){
				$next.classList.add("disable");
			}
			if( $prev.classList.contains("disable") ){
				$prev.classList.remove("disable");
			}
		}else if($this.current === 0){
			if( !$prev.classList.contains("disable") ){
				$prev.classList.add("disable");
			}
			if( $next.classList.contains("disable") ){
				$next.classList.remove("disable");
			}
		}else{
			if( $prev.classList.contains("disable") ){
				$prev.classList.remove("disable");
			}
			if( $next.classList.contains("disable") ){
				$next.classList.remove("disable");
			}
		}
	}
}
export let slide = {
	arry: [],
	init: function(){
		let $slides = document.querySelectorAll("[data-slideshow]");
		if( $slides.length > 0 ){
			$slides.forEach( ($slide, index) => {
				slide.arry[index] = new Slideshow($slide);
			});
		}
		console.log(slide.arry);
	}
}