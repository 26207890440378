export let masonry = {
	resize: function(){
		let arry = [0, 0];
		let $blocks = document.querySelectorAll(".c-access");
		if( $blocks.length > 0 ){
			$blocks.forEach( ($blk, index) => {
				$blk.style.top = arry[index%2] + "px";
				let h = $blk.clientHeight;
				if( index%2 === 0){
					arry[0] += h;
				}else{
					arry[1] += h;
				}
			});
			let listH = Math.max.apply(null, arry);
			if (window.matchMedia('all and (min-width : 960px)').matches) {
				document.querySelector(".c-list_access").style.height = listH + "px";
			}else{
				document.querySelector(".c-list_access").style.height = "";
			}
			
		}
	}
}