export let rooms = {
	target: "",
	init: function(){
		let $btns = document.querySelectorAll(".buttons button");
		if( $btns ){
			rooms.target = document.querySelector("#popup");
			rooms.nav();
			setTimeout(function(){
				rooms.activeModal(0);
			}, 200);
		}
	},
	nav: function(){
		let $btns = document.querySelectorAll(".buttons button");
		$btns.forEach( ($btn, index) => {
			$btn.removeEventListener("click", rooms.getIndex, false);
			$btn.addEventListener("click", rooms.getIndex, false);
		});
		let $closes = document.querySelectorAll("[data-close]");
		$closes.forEach( ($close, index) => {
			$close.removeEventListener("click", rooms.hidePopup, false);
			$close.addEventListener("click", rooms.hidePopup, false);
		});
	},
	getIndex: function(e){
		let $this = this;
		let idx 	= $this.getAttribute("data-id");
		let num 	= parseInt(idx) - 1;
		rooms.activeModal(num);
	},
	activeModal: function(num){
		let lists 	= rooms.target.querySelectorAll("ul li");
		let target = "";
		let $button = document.querySelectorAll(".buttons button")[num];
		lists.forEach( ($list, index) => {
			if( num === index ){
				target = $list;
				$list.classList.add("active");
			}else{
				if($list.classList.contains("active")){
					$list.classList.remove("active");
				}
			}
		});
		let pos = rooms.getPos($button);
		rooms.target.style.top = pos.top + "px";
		rooms.target.querySelector(".arrow").style.left = pos.left + "px";
		rooms.showPopup();
	},
	getPos: function(target){
		let obj = {};
		obj.top = target.offsetTop + 58;
		obj.left = target.offsetLeft + 20;
		return obj;
	},
	showPopup: function(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_popup") ){
			$html.classList.add("show_popup")
		}
	},
	hidePopup: function(){
		//console.log("hide");
		let $html = document.documentElement;
		if( $html.classList.contains("show_popup") ){
			$html.classList.remove("show_popup")
		}
	}
}